import React from 'react';
import Image from '@/components/elements/Image';
import SectionRichText from '../SectionRichText';
import { AboutPageCareersSectionTypes } from './AboutPageCareersSection.types';
import * as styles from './AboutPageCareersSection.module.scss';

export default function AboutPageCareersSection({ mainImage, secondaryImage, body }: AboutPageCareersSectionTypes) {
  return (
    <div className="container mt-22 mb-12 mb-md-17 pb-md-40 relative flex flex-col">
      <div className={styles.row}>
        <div className={styles.wrapper}>
          <div className={`flex relative ${styles.imagesWrapper}`}>
            <div className={` col-6 ${styles.mainImage}`}>
              <Image data={mainImage} fluid />
            </div>
            <div className={` col-6 ${styles.secondaryImage}`}>
              <Image data={secondaryImage} fluid />
            </div>
          </div>
          <div className={styles.bodyWrapper}>
            <SectionRichText body={body} className={styles} />
          </div>
        </div>
      </div>
    </div>
  );
}

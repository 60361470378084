// extracted by mini-css-extract-plugin
export var bodyWrapper = "AboutPageCareersSection-module--bodyWrapper--7ccd3";
export var headingH2 = "AboutPageCareersSection-module--headingH2--6bc23";
export var imagesWrapper = "AboutPageCareersSection-module--imagesWrapper--c2a7b";
export var mainImage = "AboutPageCareersSection-module--mainImage--e49a8";
export var opacityEnter = "AboutPageCareersSection-module--opacityEnter--f714e";
export var opacityExit = "AboutPageCareersSection-module--opacityExit--8f171";
export var paragraph = "AboutPageCareersSection-module--paragraph--471aa";
export var rollDown = "AboutPageCareersSection-module--rollDown--5c689";
export var rollUp = "AboutPageCareersSection-module--rollUp--aeff0";
export var row = "AboutPageCareersSection-module--row--2faa8";
export var secondaryImage = "AboutPageCareersSection-module--secondaryImage--57405";
export var slideInDown = "AboutPageCareersSection-module--slideInDown--778d5";
export var slideOutUp = "AboutPageCareersSection-module--slideOutUp--50fd8";
export var splashEnter = "AboutPageCareersSection-module--splashEnter--1abd6";
export var splashExit = "AboutPageCareersSection-module--splashExit--4c707";
export var wrapper = "AboutPageCareersSection-module--wrapper--48162";